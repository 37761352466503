import request from '@/utils/request';
/**
 *  fetch: 获取
 *  update: 修改
 *  add: 增加
 *  del: 删除
 */

export function fetchReportList(data) {
  return request({
    url: '/partner/report_daily',
    method: 'get',
    params: data
  });
}
export function fetchReportPropertyList(data) {
  return request({
    url: '/partner/report_daily/property_list',
    method: 'get',
    params: data
  });
}
export function fetchCompanyList(data) {
  return request({
    url: '/partner/company',
    method: 'get',
    params: data
  });
}
export function addCompany(data) {
  return request({
    url: '/partner/company',
    method: 'post',
    data: data
  });
}
export function deleteCompany(id) {
  return request({
    url: '/partner/company/' + id,
    method: 'delete'
  });
}
export function fetchCompanyPropertyList(data) {
  return request({
    url: '/partner/property',
    method: 'get',
    params: data
  });
}
export function fetchCompanyPropertyTotalSettleRatio(data) {
  return request({
    url: '/partner/property/totalSettleRatio',
    method: 'get',
    params: data
  });
}
export function saveCompanyProperty(data) {
  return request({
    url: '/partner/property',
    method: 'post',
    data: data
  });
}
export function updateCompanyPropertyStatus(data) {
  return request({
    url: '/partner/property',
    method: 'put',
    data: data
  });
}
export function deleteCompanyProperty(id) {
  return request({
    url: '/partner/property/' + id,
    method: 'delete'
  });
}
export function fetchCompanyMemberList(data) {
  return request({
    url: '/partner/member',
    method: 'get',
    params: data
  });
}
export function saveCompanyMember(data) {
  return request({
    url: '/partner/member',
    method: 'post',
    data: data
  });
}
export function updateCompanyMemberStatus(data) {
  return request({
    url: '/partner/member',
    method: 'put',
    data: data
  });
}
export function deleteCompanyMember(id) {
  return request({
    url: '/partner/member/' + id,
    method: 'delete'
  });
}
export function fetchFinanceCount(data) {
  return request({
    url: '/partner/finance/count',
    method: 'get',
    params: data
  });
}
export function fetchFinanceList(data) {
  return request({
    url: '/partner/finance',
    method: 'get',
    params: data
  });
}
export function financeWithdrawCheck(data) {
  return request({
    url: '/partner/finance/withdraw/check',
    method: 'put',
    data: data
  });
}
export function financeBalanceRecord(data) {
  return request({
    url: '/partner/finance/balance_record',
    method: 'get',
    params: data
  });
}